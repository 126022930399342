import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step certificate create`}</strong>{` -- create a certificate or certificate signing request`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step certificate create <subject> <crt-file> <key-file>
[--kms=<uri>] [--csr] [--profile=<profile>]
[--template=<file>] [--set=<key=value>] [--set-file=<file>]
[--not-before=<duration>] [--not-after=<duration>]
[--password-file=<file>] [--ca=<issuer-cert>]
[--ca-key=<issuer-key>] [--ca-password-file=<file>]
[--san=<SAN>] [--bundle] [--key=<file>]
[--kty=<type>] [--curve=<curve>] [--size=<size>]
[--no-password] [--insecure]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step certificate create`}</strong>{` generates a certificate or a
certificate signing request (CSR) that can be signed later using 'step
certificate sign' (or some other tool) to produce a certificate.`}</p>
    <p>{`By default this command creates x.509 certificates or CSRs for use with TLS. If
you need something else, you can customize the output using templates. See `}<strong parentName="p">{`TEMPLATES`}</strong>{` below.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`subject`}</inlineCode>{`
The subject of the certificate. Typically this is a hostname for services or an email address for people.`}</p>
    <p><inlineCode parentName="p">{`crt-file`}</inlineCode>{`
File to write CRT or CSR to (PEM format)`}</p>
    <p><inlineCode parentName="p">{`key-file`}</inlineCode>{`
File to write private key to (PEM format). This argument is optional if `}<strong parentName="p">{`--key`}</strong>{` is passed.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--kms`}</strong>{`=`}<inlineCode parentName="p">{`uri`}</inlineCode>{`
The `}<inlineCode parentName="p">{`uri`}</inlineCode>{` to configure a Cloud KMS or an HSM.`}</p>
    <p><strong parentName="p">{`--csr`}</strong>{`
Generate a certificate signing request (CSR) instead of a certificate.`}</p>
    <p><strong parentName="p">{`--profile`}</strong>{`=`}<inlineCode parentName="p">{`profile`}</inlineCode>{`
The certificate profile sets various certificate details such as
certificate use and expiration. The default profile is 'leaf' which is suitable
for a client or server using TLS.`}</p>
    <p><inlineCode parentName="p">{`profile`}</inlineCode>{` is a case-sensitive string and must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`leaf`}</strong>{`: Generate a leaf x.509 certificate suitable for use with TLS.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`intermediate-ca`}</strong>{`: Generate a certificate that can be used to sign additional leaf certificates.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`root-ca`}</strong>{`: Generate a new self-signed root certificate suitable for use as a root CA.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`self-signed`}</strong>{`: Generate a new self-signed leaf certificate suitable for use with TLS.
This profile requires the `}<strong parentName="p">{`--subtle`}</strong>{` flag because the use of self-signed leaf
certificates is discouraged unless absolutely necessary.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--template`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The certificate template `}<inlineCode parentName="p">{`file`}</inlineCode>{`, a JSON representation of the certificate to create.`}</p>
    <p><strong parentName="p">{`--set`}</strong>{`=`}<inlineCode parentName="p">{`key=value`}</inlineCode>{`
The `}<inlineCode parentName="p">{`key=value`}</inlineCode>{` pair with template data variables. Use the `}<strong parentName="p">{`--set`}</strong>{` flag multiple times to add multiple variables.`}</p>
    <p><strong parentName="p">{`--set-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The JSON `}<inlineCode parentName="p">{`file`}</inlineCode>{` with the template data variables.`}</p>
    <p><strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to
encrypt the new private key or decrypt the user submitted private key.`}</p>
    <p><strong parentName="p">{`--ca`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
The certificate authority used to issue the new certificate (PEM file).`}</p>
    <p><strong parentName="p">{`--ca-key`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
The certificate authority private key used to sign the new certificate (PEM file).`}</p>
    <p><strong parentName="p">{`--ca-password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to
decrypt the CA private key.`}</p>
    <p><strong parentName="p">{`--key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` of the private key to use instead of creating a new one (PEM file).`}</p>
    <p><strong parentName="p">{`--no-password`}</strong>{`
Do not ask for a password to encrypt the private key.
Sensitive key material will be written to disk unencrypted. This is not
recommended. Requires `}<strong parentName="p">{`--insecure`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--not-before`}</strong>{`=`}<inlineCode parentName="p">{`time|duration`}</inlineCode>{`
The `}<inlineCode parentName="p">{`time|duration`}</inlineCode>{` set in the NotBefore property of the certificate. If a
`}<inlineCode parentName="p">{`time`}</inlineCode>{` is used it is expected to be in RFC 3339 format. If a `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is
used, it is a sequence of decimal numbers, each with optional fraction and a
unit suffix, such as "300ms", "-1.5h" or "2h45m". Valid time units are "ns",
"us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--not-after`}</strong>{`=`}<inlineCode parentName="p">{`time|duration`}</inlineCode>{`
The `}<inlineCode parentName="p">{`time|duration`}</inlineCode>{` set in the NotAfter property of the certificate. If a
`}<inlineCode parentName="p">{`time`}</inlineCode>{` is used it is expected to be in RFC 3339 format. If a `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is
used, it is a sequence of decimal numbers, each with optional fraction and a
unit suffix, such as "300ms", "-1.5h" or "2h45m". Valid time units are "ns",
"us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--san`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
Add DNS or IP Address Subjective Alternative Names (SANs). Use the '--san'
flag multiple times to configure multiple SANs.`}</p>
    <p><strong parentName="p">{`--bundle`}</strong>{`
Bundle the new leaf certificate with the signing certificate. This flag requires
the `}<strong parentName="p">{`--ca`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--kty`}</strong>{`=`}<inlineCode parentName="p">{`kty`}</inlineCode>{`
The `}<inlineCode parentName="p">{`kty`}</inlineCode>{` to build the certificate upon.
If unset, default is EC.`}</p>
    <p><inlineCode parentName="p">{`kty`}</inlineCode>{` is a case-sensitive string and must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`EC`}</strong>{`: Create an `}<strong parentName="p">{`elliptic curve`}</strong>{` keypair`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`OKP`}</strong>{`: Create an octet key pair (for `}<strong parentName="p">{`"Ed25519"`}</strong>{` curve)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`RSA`}</strong>{`: Create an `}<strong parentName="p">{`RSA`}</strong>{` keypair`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--size`}</strong>{`=`}<inlineCode parentName="p">{`size`}</inlineCode>{`
The `}<inlineCode parentName="p">{`size`}</inlineCode>{` (in bits) of the key for RSA and oct key types. RSA keys require a
minimum key size of 2048 bits. If unset, default is 2048 bits for RSA keys and 128 bits for oct keys.`}</p>
    <p><strong parentName="p">{`--crv`}</strong>{`=`}<inlineCode parentName="p">{`curve`}</inlineCode>{`, `}<strong parentName="p">{`--curve`}</strong>{`=`}<inlineCode parentName="p">{`curve`}</inlineCode>{`
The elliptic `}<inlineCode parentName="p">{`curve`}</inlineCode>{` to use for EC and OKP key types. Corresponds
to the `}<strong parentName="p">{`"crv"`}</strong>{` JWK parameter. Valid curves are defined in JWA `}{`[RFC7518]`}{`. If
unset, default is P-256 for EC keys and Ed25519 for OKP keys.`}</p>
    <p><inlineCode parentName="p">{`curve`}</inlineCode>{` is a case-sensitive string and must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`P-256`}</strong>{`: NIST P-256 Curve`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`P-384`}</strong>{`: NIST P-384 Curve`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`P-521`}</strong>{`: NIST P-521 Curve`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Ed25519`}</strong>{`: Ed25519 Curve`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>
    <p><strong parentName="p">{`--subtle`}</strong></p>
    <h2>{`Exit codes`}</h2>
    <p>{`This command returns 0 on success and >0 if any error occurs.`}</p>
    <h2>{`Templates`}</h2>
    <p>{`With templates, you can customize the generated certificate or CSR.
Templates are JSON files representing a `}<a parentName="p" {...{
        "href": "https://pkg.go.dev/go.step.sm/crypto/x509util?tab=doc#Certificate"
      }}>{`certificate`}</a>{` `}{`[1]`}{`
or a `}<a parentName="p" {...{
        "href": "https://pkg.go.dev/go.step.sm/crypto/x509util?tab=doc#CertificateRequest"
      }}>{`certificate request`}</a>{` `}{`[2]`}{`.
They use Golang's `}<a parentName="p" {...{
        "href": "https://golang.org/pkg/text/template/"
      }}><inlineCode parentName="a">{`text/template`}</inlineCode></a>{` package `}{`[3]`}{` and
`}<a parentName="p" {...{
        "href": "https://masterminds.github.io/sprig/"
      }}><inlineCode parentName="a">{`Sprig`}</inlineCode></a>{` functions `}{`[4]`}{`.`}</p>
    <p>{`Here's the default template used for generating a leaf certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`{
  "subject": {{ toJson .Subject }},
  "sans": {{ toJson .SANs }},
{{- if typeIs "*sa.PublicKey" .Insecure.CR.PublicKey }}
  "keyUsage": ["keyEncipherment", "digitalSignature"],
{{- else }}
  "keyUsage": ["digitalSignature"],
{{- end }}
  "extKeyUsage": ["serverAuth", "clientAuth"]
}
`}</code></pre>
    <p>{`And this is the default template for a CSR:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`{
  "subject": {{ toJson .Subject }},
  "sans": {{ toJson .SANs }}
}
`}</code></pre>
    <p>{`In a custom template, you can change the `}<strong parentName="p">{`subject`}</strong>{`, `}<strong parentName="p">{`dnsNames`}</strong>{`,
`}<strong parentName="p">{`emailAddresses`}</strong>{`, `}<strong parentName="p">{`ipAddresses`}</strong>{`, and `}<strong parentName="p">{`uris`}</strong>{`, and you can add custom
x.509 `}<strong parentName="p">{`extensions`}</strong>{` or set the `}<strong parentName="p">{`signatureAlgorithm`}</strong>{`.`}</p>
    <p>{`For certificate templates, the common extensions `}<strong parentName="p">{`keyUsage`}</strong>{`, `}<strong parentName="p">{`extKeyUsage`}</strong>{`, and
`}<strong parentName="p">{`basicConstraints`}</strong>{` are also represented as JSON fields.`}</p>
    <p>{`Two variables are available in templates: `}<strong parentName="p">{`.Subject`}</strong>{` contains the `}<inlineCode parentName="p">{`subject`}</inlineCode>{` argument,
and `}<strong parentName="p">{`.SANs`}</strong>{` contains the SANs provided with the `}<strong parentName="p">{`--san`}</strong>{` flag.`}</p>
    <p>{`Both .Subject and .SANs are objects, and they must be converted to JSON to be used in
the template, you can do this using Sprig's `}<strong parentName="p">{`toJson`}</strong>{` function. On the .Subject
object you can access the common name string using the template variable
`}<strong parentName="p">{`.Subject.CommonName`}</strong>{`. In `}<strong parentName="p">{`EXAMPLES`}</strong>{` below, you can see how these
variables are used in a certificate request.`}</p>
    <p>{`For more information on the template properties and functions see:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`[1] https://pkg.go.dev/go.step.sm/crypto/x509util?tab=doc#Certificate
[2] https://pkg.go.dev/go.step.sm/crypto/x509util?tab=doc#CertificateRequest
[3] https://golang.org/pkg/text/template/
[4] https://masterminds.github.io/sprig/
`}</code></pre>
    <h2>{`Examples`}</h2>
    <p>{`Create a CSR and key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create foo foo.csr foo.key --csr
`}</code></pre>
    <p>{`Create a CSR using an existing private key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create --csr --key key.priv foo foo.csr
`}</code></pre>
    <p>{`Create a CSR using an existing encrypted private key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create --csr --key key.priv --password-file key.pass foo foo.csr
`}</code></pre>
    <p>{`Create a CSR and key with custom Subject Alternative Names:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create foo foo.csr foo.key --csr \\
  --san inter.smallstep.com --san 1.1.1.1 --san ca.smallstep.com
`}</code></pre>
    <p>{`Create a CSR and key - do not encrypt the key when writing to disk:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create foo foo.csr foo.key --csr --no-password --insecure
`}</code></pre>
    <p>{`Create a root certificate and key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create root-ca root-ca.crt root-ca.key --profile root-ca
`}</code></pre>
    <p>{`Create an intermediate certificate and key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create intermediate-ca intermediate-ca.crt intermediate-ca.key \\
  --profile intermediate-ca --ca ./root-ca.crt --ca-key ./root-ca.key
`}</code></pre>
    <p>{`Create a leaf certificate and key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create foo foo.crt foo.key --profile leaf \\
  --ca ./intermediate-ca.crt --ca-key ./intermediate-ca.key
`}</code></pre>
    <p>{`Create a leaf certificate and encrypt the private key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create foo foo.crt foo.key --profile leaf \\
   --password-file ./leaf.pass \\
   --ca ./intermediate-ca.crt --ca-key ./intermediate-ca.key
`}</code></pre>
    <p>{`Create a leaf certificate and decrypt the CA private key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create foo foo.crt foo.key --profile leaf \\
   --ca ./intermediate-ca.crt --ca-key ./intermediate-ca.key --ca-password-file ./intermediate.pass
`}</code></pre>
    <p>{`Create a leaf certificate and key with custom Subject Alternative Names:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create foo foo.crt foo.key --profile leaf \\
  --ca ./intermediate-ca.crt --ca-key ./intermediate-ca.key \\
  --san inter.smallstep.com --san 1.1.1.1 --san ca.smallstep.com
`}</code></pre>
    <p>{`Create a leaf certificate and key with custom validity:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create foo foo.crt foo.key --profile leaf \\
  --ca ./intermediate-ca.crt --ca-key ./intermediate-ca.key \\
  --not-before 24h --not-after 2160h
`}</code></pre>
    <p>{`Create a self-signed leaf certificate and key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create self-signed-leaf.local leaf.crt leaf.key --profile self-signed --subtle
`}</code></pre>
    <p>{`Create a root certificate and key with underlying OKP Ed25519:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create root-ca root-ca.crt root-ca.key --profile root-ca \\
  --kty OKP --curve Ed25519
`}</code></pre>
    <p>{`Create an intermediate certificate and key with underlying EC P-256 key pair:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create intermediate-ca intermediate-ca.crt intermediate-ca.key \\
  --profile intermediate-ca --ca ./root-ca.crt --ca-key ./root-ca.key --kty EC --curve P-256
`}</code></pre>
    <p>{`Create a leaf certificate and key with underlying RSA 2048 key pair:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create foo foo.crt foo.key --profile leaf \\
  --ca ./intermediate-ca.crt --ca-key ./intermediate-ca.key --kty RSA --size 2048
`}</code></pre>
    <p>{`Create a CSR and key with underlying OKP Ed25519:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create foo foo.csr foo.key --csr --kty OKP --curve Ed25519
`}</code></pre>
    <p>{`Create a root certificate using a custom template. The root certificate will
have a path length constraint that allows at least 2 intermediates:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ cat root.tpl
{
  "subject": {
    "commonName": "Acme Corporation Root CA"
  },
  "issuer": {
    "commonName": "Acme Corporation Root CA"
  },
  "keyUsage": ["certSign", "crlSign"],
  "basicConstraints": {
    "isCA": true,
    "maxPathLen": 2
  }
}
$ step certificate create --template root.tpl \\
  "Acme Corporation Root CA" root_ca.crt root_ca_key
`}</code></pre>
    <p>{`Create an intermediate certificate using the previous root. By extending the
maxPathLen we are enabling this intermediate sign leaf and intermediate
certificates. We will also make the subject configurable using the `}<strong parentName="p">{`--set`}</strong>{` and
`}<strong parentName="p">{`--set-file`}</strong>{` flags.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ cat intermediate.tpl
{
  "subject": {
    "country": {{ toJson .Insecure.User.country }},
    "organization": {{ toJson .Insecure.User.organization }},
    "organizationalUnit": {{ toJson .Insecure.User.organizationUnit }},
    "commonName": {{toJson .Subject.CommonName }}
  },
  "keyUsage": ["certSign", "crlSign"],
  "basicConstraints": {
    "isCA": true,
    "maxPathLen": 1
  }
}
$ cat organization.json
{
  "country": "US",
  "organization": "Acme Corporation",
  "organizationUnit": "HQ"
}
$ step certificate create --template intermediate.tpl \\
  --ca root_ca.crt --ca-key root_ca_key \\
  --set-file organization.json --set organizationUnit=Engineering \\
  "Acme Corporation Intermediate CA" intermediate_ca.crt intermediate_ca_key
`}</code></pre>
    <p>{`Sign a new intermediate using the previous intermediate, now with path
length 0 using the `}<strong parentName="p">{`--profile`}</strong>{` flag:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create --profile intermediate-ca \\
  --ca intermediate_ca.crt --ca-key intermediate_ca_key \\
  "Coyote Corporation" coyote_ca.crt coyote_ca_key
`}</code></pre>
    <p>{`Create a leaf certificate, that is the default profile and bundle it with
the two intermediate certificates and validate it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create --ca coyote_ca.crt --ca-key coyote_ca_key \\
  "coyote@acme.corp" leaf.crt coyote.key
$ cat leaf.crt coyote_ca.crt intermediate_ca.crt > coyote.crt
$ step certificate verify --roots root_ca.crt coyote.crt
`}</code></pre>
    <p>{`Create a certificate request using a template:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ cat csr.tpl
{
    "subject": {
        "country": "US",
        "organization": "Coyote Corporation",
        "commonName": "{{ .Subject.CommonName }}"
    },
  "sans": {{ toJson .SANs }}
}
$ step certificate create --csr --template csr.tpl --san coyote@acme.corp \\
  "Wile E. Coyote" coyote.csr coyote.key
`}</code></pre>
    <p>{`Create a root certificate using `}<inlineCode parentName="p">{`step-kms-plugin`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step kms create \\
  --kms 'pkcs11:module-path=/usr/local/lib/softhsm/libsofthsm2.so;token=smallstep?pin-value=password' \\
  'pkcs11:id=4000;object=root-key'
$ step certificate create \\
  --profile root-ca \\
  --kms 'pkcs11:module-path=/usr/local/lib/softhsm/libsofthsm2.so;token=smallstep?pin-value=password' \\
  --key 'pkcs11:id=4000' \\
  'KMS Root' root_ca.crt
`}</code></pre>
    <p>{`Create an intermediate certificate using `}<inlineCode parentName="p">{`step-kms-plugin`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step kms create \\
  --kms 'pkcs11:module-path=/usr/local/lib/softhsm/libsofthsm2.so;token=smallstep?pin-value=password' \\
  'pkcs11:id=4001;object=intermediate-key'
$ step certificate create \\
  --profile intermediate-ca \\
  --kms 'pkcs11:module-path=/usr/local/lib/softhsm/libsofthsm2.so;token=smallstep?pin-value=password' \\
  --ca root_ca.crt --ca-key 'pkcs11:id=4000' \\
  --key 'pkcs11:id=4001' \\
  'My KMS Intermediate' intermediate_ca.crt
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      